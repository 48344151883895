import { Buffer } from "buffer";
import * as splToken from "@solana/spl-token"

// import * as SplToken from "@solana/spl-token";
window.Buffer = Buffer;
import { useState } from "react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Input,
  Text,
  Tooltip,
  VStack,
  useDisclosure
} from "@chakra-ui/react";
import nacl from "tweetnacl";
import { truncateAddress } from "./utils";
import SelectWalletModal from "./Modal";

import { connectWallet, WalletServices, emitConnectedWithSignature, emitWalletError,
  OnSplCreateMint, OnSplCreateTransferInstruction, OnSplMintTo, OnSplGetOrCreateAssociatedTokenAccount, OnSplCreateTransferCheckedInstruction, onSplTOKEN_PROGRAM_ID,
  OnSplToken
 } from "./authservice"


export default function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [provider, setProvider] = useState();
  const [account, setAccount] = useState();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [signature, setSignature] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [verified, setVerified] = useState();
  let [loginResponse, setLoginResponse] = useState();
  let isWebDesktop = true;

  const connectWithProvider = async (provider) => {
    try {
      if (!provider) throw Error("Wallet not found.");
      setProvider(provider);
      await provider.connect();
      if (provider.publicKey) {
        setAccount(provider.publicKey.toString());
        signMessage('UpNUpPoker', provider)
      }
    } catch (error) {
      emitWalletError(error);
      setError(error);
    }
  };


  WalletServices(() => {
    onOpen();
  });

  // OnSplCreateMint(createMint);
  // OnSplCreateTransferInstruction(createTransferInstruction);
  // OnSplMintTo(mintTo);
  // OnSplGetOrCreateAssociatedTokenAccount(getOrCreateAssociatedTokenAccount);
  // OnSplCreateTransferCheckedInstruction(createTransferCheckedInstruction);
  // onSplTOKEN_PROGRAM_ID(TOKEN_PROGRAM_ID);
  OnSplToken(splToken);
  // OnSplToken(Token);
  const signMessage = async (message, provider) => {
    if (!provider) return;

    try {
      let encodedMessage = new TextEncoder().encode(message);
      let { signature } = await provider.signMessage(encodedMessage);
      setSignedMessage(encodedMessage);
      setSignature(signature);
      encodedMessage = Buffer.from(encodedMessage).toString('hex');
      signature = Buffer.from(signature).toString('hex');

      if (!isWebDesktop) {
        console.log(encodedMessage, signature);
        console.log(provider.publicKey.toBuffer(), provider.publicKey.toBuffer().toString('hex'));
        const res = await connectWallet(provider.publicKey.toBuffer().toString('hex'), signature);
        const content = await res.json();
        if (content && content.status === 'success' && content.access_token && content.refresh_token) {
          setLoginResponse(content)
        }
        console.log(content);
      } else {
        if (provider == window.coinbaseSolana) {
          window.isUsingCoinBase = true;
        }
        emitConnectedWithSignature(signature, provider.publicKey);
      }
    } catch (error) {
      emitWalletError(error);
      setError(error);
    }
  };

  const refreshState = () => {
    setAccount();
    setMessage("");
    setSignature("");
    setVerified(undefined);
  };

  const disconnect = async () => {
    await provider.disconnect();
    refreshState();
  };

  return (
    <>

      <VStack justifyContent="center" alignItems="center" h="100vh">
        <HStack marginBottom="10px">
          <Text
            margin="0"
            lineHeight="1.15"
            fontSize={["1.5em", "2em", "3em", "4em"]}
            fontWeight="600"
          >
            Let's connect to
          </Text>
          <Text
            margin="0"
            lineHeight="1.15"
            fontSize={["1.5em", "2em", "3em", "4em"]}
            fontWeight="600"
            sx={{
              background: "linear-gradient(15deg, #DC1FFF 0%, #00FFA3 100.00%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent"
            }}
          >
            Solana
          </Text>
        </HStack>
        <HStack>
          {!account ? (
            <Button onClick={onOpen}>Connect</Button>
          ) : (
            <Button onClick={disconnect}>Disconnect</Button>
          )}
        </HStack>
        <VStack justifyContent="center" alignItems="center" padding="10px 0">
          <HStack>
            <Text>{`Connection Status: `}</Text>
            {account ? (
              <CheckCircleIcon color="green" />
            ) : (
              <WarningIcon color="#cd5700" />
            )}
          </HStack>

          {account ? (
            <Tooltip label={account} placement="right">
              <Text>{`Account: ${truncateAddress(account)}`}</Text>
            </Tooltip>
          ) : (
            <Text>{`Account: No Account`}</Text>
          )}
        </VStack>
        <HStack>
          {loginResponse && loginResponse.status === 'success' ?
            <Button onClick={onOpen}>Go back to the game</Button>
            : null
          }
        </HStack>
        <Text>{error ? error.message : null}</Text>
      </VStack>
      <SelectWalletModal
        isOpen={isOpen}
        closeModal={onClose}
        connectWithProvider={connectWithProvider}
      />
    </>
  );
}
