import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";

import App from "./App";
import { OpenModal, OnConnected, OnWalletError, getCreateTransferCheckedInstruction, getGetOrCreateAssociatedTokenAccount, getTOKEN_PROGRAM_ID, getSplToken } from "./authservice"

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </StrictMode>
);


window.OnWalletConnected = OnConnected;
window.OpenWalletModal = OpenModal;
window.OnWalletError = OnWalletError;
window.getCreateTransferCheckedInstruction = getCreateTransferCheckedInstruction;
window.getGetOrCreateAssociatedTokenAccount = getGetOrCreateAssociatedTokenAccount;
window.getTOKEN_PROGRAM_ID = getTOKEN_PROGRAM_ID;
window.getSplToken = getSplToken;
// OnConnected((signature, publicKey)=>{
//   console.log('Connected To address', publicKey.toString());
//   console.log('Signature to verify', signature);
// });

// setTimeout(() => {
//   OpenModal();
// }, 5000);