const apiUrl = 'http://18.191.46.120:7000';
let openFnc;
let onConnectedFnc;
let onErrorFnc;
let splCreateMint;
let splGetOrCreateAssociatedTokenAccount;
let splMintTo;
let splCreateTransferInstruction;
let splToken;
let TOKEN_PROGRAM_ID;
export let splCreateTransferCheckedInstruction;
export const getCreateTransferCheckedInstruction = () => {
    return splCreateTransferCheckedInstruction;
}
export const getTOKEN_PROGRAM_ID = () =>{
    return TOKEN_PROGRAM_ID;
}

export const getSplToken = () => {
    return splToken;
}
export const getGetOrCreateAssociatedTokenAccount = () =>{
    return splGetOrCreateAssociatedTokenAccount;
}
export const connectWallet = (publickey, signature) => {
    const body = {
        publicKey: publickey,
        signature: signature
    }
    return fetch(apiUrl + '/api/auth/wallet-connect', { method: 'POST', headers: { accept: 'application/json', "Content-Type":'application/json' }, body: JSON.stringify(body)  })
}

export const WalletServices = (openRef) => {
    openFnc = openRef;
};

export const OpenModal = () =>{
    openFnc();
}

export const OnSplToken = (cb) =>{
    splToken = cb;
}

export const onSplTOKEN_PROGRAM_ID = (cb) => {
    TOKEN_PROGRAM_ID = cb;
}

export const OnSplCreateMint = (cb) =>{
    splCreateMint = cb;
}

export const OnSplCreateTransferCheckedInstruction = (cb) => {
    splCreateTransferCheckedInstruction = cb;
}

export const OnSplGetOrCreateAssociatedTokenAccount = (cb) =>{
    splGetOrCreateAssociatedTokenAccount = cb;
}

export const OnSplMintTo = (cb) =>{
    splMintTo = cb;
}

export const OnSplCreateTransferInstruction = (cb) =>{
    splCreateTransferInstruction = cb;
}

export const OnConnected = (cb) =>{
    onConnectedFnc = cb;
}

export const OnWalletError = (cb) =>{
    onErrorFnc = cb;
}

export const emitConnectedWithSignature = (signature, publickey) => {
    if (onConnectedFnc) {
        onConnectedFnc(signature, publickey);
    }
}

export const emitWalletError = (error) => {
    if (onErrorFnc) {
        onErrorFnc(error);
    }
}


